import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import {
  Alert,
  AlertTitle,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import { memo } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import ButtonControl from "../../components/controls/ButtonControl";
import InputControl from "../../components/controls/InputControl";
import SelectControl from "../../components/controls/SelectControl";
import { countriesArr } from "../../scripts/Scripts";

function PersonalInfoForm(props) {
  const {
    values,
    setValues,
    handleInputChange,
    errors,
    isEmailConflict,
    setEmailConflict,
    isNotFound,
    setNotFound,
  } = props;

  const resetConflict = () => {
    setEmailConflict(false);
    setNotFound(false);
    values.customerPin = "";
  };

  return (
    <Card elevation={3} sx={{ height: "100%" }}>
      <CardHeader
        avatar={
          <Avatar>
            <PermIdentityOutlinedIcon />
          </Avatar>
        }
        title="Personal"
        titleTypographyProps={{ fontSize: "inherit" }}
        subheader=" Info"
      />
      <Divider />
      <CardContent>
        <InputControl
          type="email"
          label="Email"
          name="email"
          value={values.email}
          onChange={handleInputChange}
          error={errors.email}
          disabled={isEmailConflict}
        />
        {isEmailConflict && (
          <>
            <Alert severity={!isNotFound ? "success" : "error"}>
              {!isNotFound ? (
                <>
                  <AlertTitle>HOORAY! You are already a customer!</AlertTitle>
                  Please check your email for a Customer PIN from 2wrap.com and
                  validate it here (also check your spam-filter)
                </>
              ) : (
                <AlertTitle>Customer PIN is not Valid!</AlertTitle>
              )}

              <InputControl
                type="password"
                label="Customer PIN"
                name="customerPin"
                value={values.customerPin}
                onChange={handleInputChange}
                error={errors.customerPin}
              />
              <ButtonControl
                variant="text"
                text="Use Different Email address"
                onClick={resetConflict}
                fullWidth
              />
            </Alert>
          </>
        )}
        <InputControl
          label="First Name"
          name="firstName"
          value={values.firstName}
          onChange={handleInputChange}
          error={errors.firstName}
        />
        <InputControl
          label="Last Name"
          name="lastName"
          value={values.lastName}
          onChange={handleInputChange}
          error={errors.lastName}
        />
        <PhoneInput
          country="us"
          enableSearch={true}
          value={values.phoneNumber}
          onChange={(phone) =>
            setValues({
              ...values,
              phoneNumber: phone === "" ? phone : "+" + phone,
            })
          }
          inputProps={{ required: true }}
          containerStyle={{
            marginTop: "16px",
            marginBottom: "8px",
          }}
          inputStyle={{
            width: "100%",
          }}
        />
        <InputControl
          label="Address"
          name="address"
          value={values.address}
          onChange={handleInputChange}
          error={errors.address}
        />
        <InputControl
          label="City"
          name="city"
          value={values.city}
          onChange={handleInputChange}
          error={errors.city}
        />
        <InputControl
          label="State"
          name="state"
          value={values.state}
          onChange={handleInputChange}
          error={errors.state}
        />
        <SelectControl
          label="Country"
          name="country"
          value={values.country}
          onChange={handleInputChange}
          options={countriesArr}
          textIndex={0}
        />
        <InputControl
          label="Zip Code"
          name="zipCode"
          value={values.zipCode}
          onChange={handleInputChange}
          error={errors.zipCode}
        />
        <InputControl
          label="Company"
          name="company"
          value={values.company}
          onChange={handleInputChange}
          error={errors.company}
        />
        <InputControl
          label="Tax No."
          name="taxNumber"
          value={values.taxNumber}
          onChange={handleInputChange}
          error={errors.taxNumber}
        />
        <SelectControl
          label="Where did you find us"
          name="whereFindUs"
          value={values.whereFindUs}
          onChange={handleInputChange}
          options={[
            { value: "Social Media", label: "Social Media" },
            { value: "TV", label: "TV" },
            { value: "Friend", label: "Friend" },
            { value: "Google", label: "Google" },
            { value: "Other", label: "Other" },
          ]}
        />
      </CardContent>
    </Card>
  );
}

export default memo(PersonalInfoForm);
